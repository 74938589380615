import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"
import AnimationSection from "../components/animation-section"
import ProductPreview from "../components/product-preview"
import RequestDemoSection from "../components/request-demo-section"
import ContentIntelligenceContent from "../content/content-intelligence"
import productVideo from "../assets/videos/ci/top-1.mp4"

const CTA_TEXT = "Request Demo"

class ContentIntelligencePage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (
      typeof window !== "undefined" &&
      document.location.href.indexOf("#demo") !== -1
    ) {
      setTimeout(() => {
        document.querySelector("#request-a-demo").scrollIntoView({
          behavior: "smooth",
        })
      }, 300)
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title="AI-Powered Content Intelligence Platform"
          description="Identify your most valuable content across each stage of the funnel and optimize your advertising strategy."
        />
        <ProductHero
          title={[
            "Identify Your Most ",
            <strong class="has-text-weight-bold">Valuable Content</strong>,
          ]}
          subtitle="Automatically prompt users to perform your desired actions at the ideal time and measure what content drives ROI"
        >
          <AnchorLink href="#request-a-demo" offset="160">
            <button className="button is-cta-button">{CTA_TEXT}</button>
          </AnchorLink>
        </ProductHero>
        <ProductPreview video={productVideo} />
        {ContentIntelligenceContent.map((section, index) => (
          <AnimationSection
            key={section.key}
            title={section.title}
            description={section.description}
            video={section.video}
            direction={section.direction}
            ctaText={CTA_TEXT}
          />
        ))}
        <RequestDemoSection
          title={CTA_TEXT}
          formPlacement="Content Intelligence"
        />
        <AgenciesMarquee />
      </Layout>
    )
  }
}

export default ContentIntelligencePage
