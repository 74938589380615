import React from "react"

export default [
  {
    title: "Display the right <strong>call-to-action</strong>  at the <strong>right time</strong>",
    description: "Drive audiences to take your desired actions by using AI to test various call-to-actions displayed at different times and automatically show the right call-to-action at the ideal time to drive desired outcomes such as Engagement, Positive Consideration, and Website Actions.",
    video: require("../assets/videos/ci/1.mp4"),
    direction: 'ltr',
  },
  {
    title: "Measure and identify your best <strong>performing content</strong>",
    description: "Pageviews are great but knowing what content delivers real business results is everything. Whether your goal is to drive Engagement, Positive Consideration, or Website Actions our AI automatically prompts users to perform your desired actions and reports on what content is most effective at driving each goal.",
    video: require("../assets/videos/ci/2.mp4"),
    direction: 'rtl',
  },
  {
    title: "Seamlessly <strong>manage call-to-actions</strong> across your content hub",
    description: "Drop a single tag across all pages of your content hub and create and manage various CTAs across different blog posts from a single interface. Assign multiple CTAs to specific blog posts, categories of content, or across the entire blog and make changes at any time - say goodbye to static call-to-actions.",
    video: require("../assets/videos/ci/3.mp4"),
    direction: 'ltr',
  },
  {
    title: "Capture <strong>high-performing</strong> first party audiences",
    description: "Feed your retargeting pool with quality scalable first party data proven to convert. Build custom audiences based on amount of time spent engaging with your content, average scroll depth, and interactions with your call-to-actions.",
    video: require("../assets/videos/ci/4.mp4"),
    direction: 'rtl',
  },
  {
    title: "Measure the <strong>effectiveness</strong> of every piece of <strong>content</strong>",
    description: "Your most effective content does not always receive the highest traffic. This is why we developed AI that knows when to prompt users to take action with a user-friendly experience proven to perform so you can reach statistical significance quickly and measure effectiveness of every piece of content.",
    video: require("../assets/videos/ci/5.mp4"),
    direction: 'ltr',
  },
  {
    title: "Deliver a <strong>great user experience</strong> every time",
    description: "Driving tangible business outcomes should not come at the expense of users. That’s why we have developed tools to help you customize look and feel of your call-to-actions and enable you to set frequency controls that ensure users have a great experience every single time.",
    video: require("../assets/videos/ci/6.mp4"),
    direction: 'rtl',
  }
]
